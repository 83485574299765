import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { MdAdsClick, MdClose, MdCodeOff, MdExpandMore, MdMarkEmailUnread, MdOutlineApartment, MdOutlineBadge, MdOutlineChecklistRtl, MdOutlineCreditCard, MdOutlineHealthAndSafety, MdOutlineLeaderboard, MdOutlineMessage, MdOutlineMonetizationOn, MdOutlineNotifications, MdOutlinePersonAdd, MdOutlinePhoneInTalk, MdOutlineSettings, MdOutlineStorefront, MdOutlineSupervisorAccount, MdSchedule, MdSpeed, MdStarOutline } from "react-icons/md";
import SidebarContext from "./Context";
import { Fragment, useContext, useEffect, useState } from "react";
import { Avatar, AvatarGroup, Button, Collapse, IconButton, Slide } from "@mui/material";
import { api_online_users_path, api_online_path, call_logs_new_path, call_logs_records_path, call_logs_sms_records_path, daily_productions_dashboard_path, daily_productions_path, employee_time_path, employees_path, generals_path, google_reviews_path, healthy_sales_path, leads_path, new_daily_production_path, offices_path, patient_forms_path, patients_path, open_dental_break_appointment_path, open_dental_doctor_production_path, new_patients_path, income_insurances_path, hygienist_average_productions_path, open_dental_payment_total_index_path, open_dental_list_path, appointment_per_employees_path, patient_dentrix_index_path, send_review_path, leads_dashboard_leads_path, inform_payment_index_path, new_inform_payment_path, healthy_sales_new_path, message_log_google_review_path, staff_index_path, procedural_codes_path, home_path, employee_time_adjusted_path, ecommerces_path, new_sms_campaign_path, sms_campaigns_path, meta_ads_path, google_ads_path, voicemails_path, google_business_insights_path, new_collection_path, collections_path, webex_index_path } from "@/compiled/routes";
import { FaSms, FaTooth } from "react-icons/fa";
import { Link, usePage } from "@inertiajs/inertia-react";
import { Fetch } from "@/helpers/fetch";
import randomHex from "@/helpers/randomHex";
import BadgeOnline from "../Badge/Online";
import { ReactComponent as OpenDentalSVG } from "@/assets/svg/open_dental_ico.svg";
import { ReactComponent as WebexIconSVG } from "@/assets/svg/webex_icon.svg";
import { TbMoneybag } from "react-icons/tb";

const Sidebar = ({
  chatToggle,
  chatOpen,
}) => {
  const { LOGO, CURRENT_USER, PERMISSIONS, MOUSE_ACTIVITY_THROTTLING, BASE_URL } = usePage().props;
  const { open, handle } = useContext(SidebarContext);
  const [openSub, setOpenSub] = useState(true);

  const [onlineUsers, setOnlineUsers] = useState([]);
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
  const [debounceAnimation, setDebounceAnimation] = useState(false);
  const [IsIOs, setIsIOs] = useState(false);

  const { t } = useTranslation();

  const path = window.location.pathname;

  const sidebarItems = [
    {
      id: "open_dental",
      name: false,
      items: [
        {
          visible: PERMISSIONS.view_open_dental,
          id: "open_dental",
          icon: <OpenDentalSVG className="flex-none w-5 h-5" />,
          text: t("open_dental_ai_sync"),
          sub: [
            {
              text: t("patient_list"),
              to: open_dental_list_path()
            },
            {
              text: t("doctor_production"),
              to: open_dental_doctor_production_path()
            },
            {
              text: t("break_appointments"),
              to: open_dental_break_appointment_path()
            },
            // {
            //   text: t("sidebar.aging"),
            //   to: aging_index_path()
            // },
            {
              text: t("sidebar.new_patients"),
              to: new_patients_path()
            },
            {
              text: t("sidebar.income_insurances"),
              to: income_insurances_path()
            },
            {
              text: t("sidebar.hygienist_average_productions"),
              to: hygienist_average_productions_path()
            },
            {
              text: t("payments"),
              to: open_dental_payment_total_index_path()
            },
            {
              text: t("appointment_per_employee"),
              to: appointment_per_employees_path()
            },
          ]
        }
      ]
    },
    {
      id: "webex",
      name: false,
      items: [
        {
          visible: PERMISSIONS.view_webex_calling_call_history,
          id: "webex_api_sync",
          icon: <WebexIconSVG className="flex-none w-5 h-5" />,
          text: t("webex_api_sync"),
          sub: [
            {
              text: t("calls"),
              to: webex_index_path()
            },
          ]
        }
      ]
    },
    {
      id: "navigation",
      name: t("sidebar.navigation"),
      items: [
        {
          visible: PERMISSIONS.view_call_log_dashboard,
          icon: <MdSpeed className="flex-none w-6 h-6" />,
          text: t("sidebar.dashboard"),
          to: "/dashboard"
        },
        {
          visible: PERMISSIONS.patients_index,
          icon: <MdOutlineSupervisorAccount className="flex-none w-6 h-6" />,
          text: t("sidebar.patients"),
          to: patients_path()
        },
        {
          visible: window.innerWidth < 765,
          icon: <MdMarkEmailUnread className="flex-none w-6 h-6" />,
          text: t("communications"),
          to: home_path()
        }
      ]
    },
    {
      id: "utilities",
      name: t("sidebar.utilities"),
      items: [
        {
          visible: PERMISSIONS.view_and_register_call_log,
          id: "sidebar_call_log",
          icon: <MdOutlinePhoneInTalk className="flex-none w-6 h-6" />,
          text: t("sidebar.call_log"),
          sub: [
            {
              text: t("new"),
              to: call_logs_new_path()
            },
            {
              text: t("sidebar.records"),
              to: call_logs_records_path()
            },
            {
              visible: PERMISSIONS.sms_records,
              text: t("sidebar.sms_records"),
              to: call_logs_sms_records_path()
            },
            {
              visible: PERMISSIONS.view_voicemails,
              // icon: <MdVoicemail className="flex-none w-6 h-6" />,
              text: t("sidebar.voicemail"),
              to: voicemails_path()
            },
          ]
        },
        {
          visible: PERMISSIONS.view_and_send_sms_messenger,
          icon: <MdOutlineMessage className="flex-none w-6 h-6" />,
          text: t("sidebar.sms_messenger"),
          to: "/messenger"
        },
        {
          id: "sidebar_leads",
          visible: PERMISSIONS.leads,
          icon: <MdOutlineLeaderboard className="flex-none w-6 h-6" />,
          text: t("sidebar.leads"),
          sub: [
            {
              visible: PERMISSIONS.leads,
              text: t("list"),
              to: leads_path()
            },
            {
              visible: PERMISSIONS.leads,
              text: t("leads_report"),
              to: leads_dashboard_leads_path()
            },
          ]
        },
        {
          visible: PERMISSIONS.view_dentrix_patient,
          icon: <FaTooth className="flex-none w-6 h-6 stroke-[35px] stroke-[#757575] fill-white" />,
          text: t("patient_dentrix"),
          to: patient_dentrix_index_path()
        },
      ]
    },
    {
      id: "administration",
      name: t("sidebar.administration"),
      items: [
        {
          visible: ["luciovillagran89@gmail.com", "luisabreumolina97@gmail.com"].includes(CURRENT_USER.email),
          icon: <MdSchedule className="flex-none w-6 h-6" />,
          text: t("sidebar.employee_time_real"),
          to: employee_time_path()
        },
        {
          visible: PERMISSIONS.employee_time,
          icon: <MdSchedule className="flex-none w-6 h-6" />,
          text: t("sidebar.employee_time"),
          to: employee_time_adjusted_path()
        },
        {
          id: "sidebar_daily_productions",
          visible: PERMISSIONS.view_daily_production_index || PERMISSIONS.view_daily_production_dashboard || PERMISSIONS.add_daily_production_records,
          icon: <MdOutlineMonetizationOn className="flex-none w-6 h-6" />,
          text: t("sidebar.daily_productions"),
          sub: [
            {
              visible: PERMISSIONS.add_daily_production_records,
              text: t("new"),
              to: new_daily_production_path()
            },
            {
              visible: PERMISSIONS.view_daily_production_dashboard,
              text: t("dashboard"),
              to: daily_productions_dashboard_path()
            },
            {
              visible: PERMISSIONS.view_daily_production_index,
              text: t("sidebar.daily_productions"),
              to: daily_productions_path()
            },
          ]
        },
        {
          id: "collection",
          visible: PERMISSIONS.view_collections || PERMISSIONS.create_collections,
          icon: <TbMoneybag className="flex-none w-6 h-6" />,
          text: t("collection"),
          sub: [
            {
              visible: PERMISSIONS.view_collections,
              text: t("new"),
              to: new_collection_path()
            },
            {
              visible: PERMISSIONS.create_collections,
              text: t("list"),
              to: collections_path()
            },
          ]
        },
        {
          id: "google_map_location",
          visible: PERMISSIONS.google_reviews,
          icon: <MdStarOutline className="flex-none w-6 h-6" />,
          text: t("google_map_location"),
          sub: [
            {
              visible: PERMISSIONS.google_reviews,
              text: t("list"),
              to: google_reviews_path()
            },
            {
              visible: PERMISSIONS.google_reviews,
              text: t("request_review"),
              to: send_review_path()
            },
            {
              visible: PERMISSIONS.google_reviews,
              text: t("message_log"),
              to: message_log_google_review_path()
            },
            {
              visible: PERMISSIONS.true,
              text: t("business_insights"),
              to: google_business_insights_path()
            },
          ]
        },
        {
          visible: PERMISSIONS.view_ecommerces,
          icon: <MdOutlineStorefront className="flex-none w-6 h-6" />,
          text: t("sidebar.ecommerce"),
          to: ecommerces_path()
        },
        {
          visible: PERMISSIONS.patients_form,
          icon: <MdOutlineChecklistRtl className="flex-none w-6 h-6" />,
          text: t("sidebar.patient_forms"),
          to: patient_forms_path()
        },
        {
          id: "sidebar_healthy",
          visible: PERMISSIONS.view_healthy_sales_list_index || PERMISSIONS.add_and_edit_healthy_sales,
          icon: <MdOutlineHealthAndSafety className="flex-none w-6 h-6" />,
          text: t("healthy_sales_list"),
          sub: [
            {
              visible: PERMISSIONS.add_and_edit_healthy_sales,
              text: t("new"),
              to: healthy_sales_new_path()
            },
            {
              visible: PERMISSIONS.view_healthy_sales_list_index,
              text: t("records"),
              to: healthy_sales_path()
            },
          ]
        },
        {
          id: "sidebar_inform_payment",
          visible: PERMISSIONS.create_inform_payment || PERMISSIONS.view_inform_payment,
          icon: <MdOutlineCreditCard className="flex-none w-6 h-6" />,
          text: t("inform_payment_plan_pd"),
          sub: [
            {
              visible: PERMISSIONS.create_inform_payment,
              text: t("new"),
              to: new_inform_payment_path()
            },
            {
              visible: PERMISSIONS.view_inform_payment,
              text: t("list"),
              to: inform_payment_index_path()
            },
          ]
        },
        {
          visible: PERMISSIONS.view_staff || PERMISSIONS.create_edit_delete_staff,
          icon: <MdOutlinePersonAdd className="flex-none w-6 h-6" />,
          text: t("staff"),
          to: staff_index_path()
        },
        {
          visible: PERMISSIONS.view_procedure_code || PERMISSIONS.create_edit_delete_procedure_code,
          icon: <MdCodeOff className="flex-none w-6 h-6" />,
          text: t("procedures_codes"),
          to: procedural_codes_path()
        },
      ]
    },
    {
      id: "marketing",
      name: t("sidebar.marketing"),
      items: [
        {
          id: "sidebar_sms_campaign",
          visible: PERMISSIONS.view_and_create_sms_campaign,
          icon: <FaSms className="flex-none w-6 h-6" />,
          text: t("sidebar.sms_campaign"),
          sub: [
            {
              visible: true,
              text: t("list"),
              to: sms_campaigns_path()
            },
            {
              visible: true,
              text: t("new"),
              to: new_sms_campaign_path()
            },
          ]
        },
        {
          id: "campaign_report",
          visible: PERMISSIONS.marketing_metrics,
          icon: <MdAdsClick className="flex-none w-6 h-6" />,
          text: t("campaign_report"),
          sub: [
            {
              visible: true,
              text: t("meta_ads"),
              to: meta_ads_path()
            },
            {
              visible: true,
              text: t("google_ads"),
              to: google_ads_path()
            },
          ]
        }
      ]
    },
    {
      id: "settings",
      name: t("sidebar.settings"),
      items: [
        {
          visible: true,
          icon: <MdOutlineNotifications className="flex-none w-6 h-6" />,
          text: t("sidebar.notifications"),
          to: "/notifications"
        }
      ]
    },
    {
      id: "superadministration",
      name: t("sidebar.superadministration"),
      items: [
        {
          visible: PERMISSIONS.general_configuration,
          icon: <MdOutlineSettings className="flex-none w-6 h-6" />,
          text: t("sidebar.general"),
          to: generals_path()
        },
        {
          visible: PERMISSIONS.employees_index,
          icon: <MdOutlineBadge className="flex-none w-6 h-6" />,
          text: t("sidebar.employees"),
          to: employees_path()
        },
        {
          visible: PERMISSIONS.offices_index,
          icon: <MdOutlineApartment className="flex-none w-6 h-6" />,
          text: t("sidebar.offices"),
          to: offices_path()
        },
      ]
    }
  ];

  const handleClick = (x) => {
    setOpenSub(x.id == openSub ? "" : x.id);
  };

  const getProfileImage = (user) => {
    if (user.profile_image) {
      return {
        src: user.profile_image,
        alt: user.display_name
      };
    } else {
      return {
        children: `${user.first_name[0]}${user.last_name[0]}`
      };
    }
  };

  useEffect(() => {
    let found = false;
    sidebarItems.forEach((sidebarItem) => {
      sidebarItem.items.forEach((item) => {
        item.sub && item.sub.forEach((x) => {
          if (x.to === path) {
            setOpenSub(item.id);
            found = true;
          }
        });
      });
    });
    if (!found) {
      setOpenSub("");
    }
  }, [path]);

  useEffect(() => {

    let lastRequestTime = 0;
    let sendRequest = false;

    const handle = () => {
      const fetch = () => {

        if (sendRequest) {
          return;
        }

        sendRequest = true;

        Fetch.put(api_online_path())
          .then(({ data }) => {
            const users = data.online_users;
            setOnlineUsers(users);
            sendRequest = false;
          });
      };

      if (lastRequestTime == 0) {
        lastRequestTime = Date.now() + (MOUSE_ACTIVITY_THROTTLING * 60 * 1000);
        fetch();
      } else {
        if (lastRequestTime < Date.now()) {
          lastRequestTime = Date.now() + (MOUSE_ACTIVITY_THROTTLING * 60 * 1000);
          lastRequestTime = 0;
          fetch();
        }
      }
    };

    document.addEventListener("click", handle);
    document.addEventListener("mousemove", handle);

    return () => {
      document.removeEventListener("click", handle);
      document.removeEventListener("mousemove", handle);
    };
  }, []);

  useEffect(() => {
    const handle = ({ detail: cable_data }) => {
      const { data } = cable_data;
      const users = data.online_users;
      setOnlineUsers(users);
    };

    document.addEventListener("USER_ONLINE_LOAD_CABLE", handle);
    return () => document.removeEventListener("USER_ONLINE_LOAD_CABLE", handle);
  }, []);


  useEffect(() => {
    const handle = ({ detail: cable_data }) => {
      const { data } = cable_data;

      if (totalUnreadMessages != data.total_unread_messages_general) {
        if (!data.sound_off && CURRENT_USER.notification_sound) {
          const audio = new Audio(`${BASE_URL}/sound/notification.wav`);
          audio.play();
        }
        setDebounceAnimation(true);
        setTotalUnreadMessages(data.total_unread_messages_general);
      }

    };

    document.addEventListener("QUICK_CHAT_RECEIVER_CABLE", handle);
    return () => document.removeEventListener("QUICK_CHAT_RECEIVER_CABLE", handle);
  }, [totalUnreadMessages]);

  useEffect(() => {
    const handle = ({ detail: cable_data }) => {
      const { data } = cable_data;
      setTotalUnreadMessages(data.total_unread_messages_general);
    };

    document.addEventListener("QUICK_CHAT_UNREAD_MESSAGE_CABLE", handle);
    return () => document.removeEventListener("QUICK_CHAT_UNREAD_MESSAGE_CABLE", handle);
  }, [totalUnreadMessages]);

  useEffect(() => {
    Fetch.get(api_online_users_path())
      .then(({ data }) => {
        const users = data.online_users;
        setOnlineUsers(users);
      });
  }, []);

  useEffect(() => {
    if (chatOpen) {
      setDebounceAnimation(false);
    }
  }, [chatOpen, totalUnreadMessages]);

  useEffect(() => {
      const  isIOS = /iPad|iPhone|iPod/.test(navigator.platform) ||
                     (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
                     (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

      setIsIOs(isIOS);
  }, []);

  return (
    <Slide direction="right" in={open} mountOnEnter unmountOnExit>
      <div
        id="sidebar"
        className={`fixed w-screen lg:w-64 flex-none min-h-screen max-h-screen bg-white border-r border-[#f5f5f5] bottom-0 top-0 z-30 ${window.innerWidth < 1024 && chatOpen && "!hidden"}`}
      >
        <div className="flex items-start justify-between p-4 text-black">
          <div>
            {LOGO && (
              <img src={LOGO} alt="" className="w-full h-auto max-w-[128px]" />
            )}
          </div>
          <div className="lg:hidden">
            <IconButton
              onClick={() => handle(!open)}
            >
              <MdClose className="flex-none w-6 h-6" />
            </IconButton>
          </div>
        </div>
        <div className={`lg:h-[calc(100%-125px)] overflow-auto ${IsIOs ? "h-[calc(100%-240px)]" : "h-[calc(100%-185px)]"}`}>
          {sidebarItems.filter(x => x.items.some(y => y.visible)).map((item, indexItems) => (
            <div key={indexItems}>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <Fragment>
                    {item.name && (
                      <ListSubheader component="div" className="!bg-transparent !relative !text-xs !mb-1">
                        {item.name}
                      </ListSubheader>
                    )}
                  </Fragment>
                }
              >
                {
                  item.items.filter(x => x.visible).map((x, index) => (
                    <Fragment key={index}>
                      {x.to && (
                        <Link href={x.to}>
                          <ListItemButton
                            sx={{
                              borderRight: (path == x.to || path.startsWith(`${x.to}/`)) && "6px solid",
                              borderColor: (path == x.to || path.startsWith(`${x.to}/`)) && "#1890FF",
                            }}
                            onClick={() => {
                              if (window.innerWidth < 1000) {
                                handle(!open);
                              }
                            }}
                            className={`${(path == x.to || path.startsWith(`${x.to}/`)) && ("!bg-primary-100")}`}
                          >
                            <ListItemIcon sx={{ minWidth: 45 }}>
                              {x.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={x.text}
                              primaryTypographyProps={{
                                className: "!text-[15px]"
                              }}
                            />
                          </ListItemButton>
                        </Link>
                      )}
                      {!x.to && (
                        <Fragment>
                          <ListItemButton onClick={() => handleClick(x)}>
                            <ListItemIcon sx={{ minWidth: 45 }}>
                              {x.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={x.text}
                              primaryTypographyProps={{
                                className: "!text-[15px]"
                              }}
                            />
                            <MdExpandMore className={`transition duration-200 ease-linear ${openSub === x.id ? "rotate-180" : ""}`} />
                          </ListItemButton>
                          {x.sub && x.sub.length > 0 && (
                            <Collapse in={openSub === x.id} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                {x.sub.map((e, i) => (
                                  <Fragment key={i}>
                                    {(e.visible == true || e.visible == undefined) && (
                                      <Link href={e.to}>
                                        <ListItemButton
                                          key={i}
                                          className={`"space-y-0" ${path == e.to && ("!bg-primary-100")}`}
                                          onClick={() => {
                                            if (window.innerWidth < 1000) {
                                              handle(!open);
                                            }
                                          }}
                                        >
                                          <ListItemIcon sx={{ minWidth: 45 }}>
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={e.text}
                                            primaryTypographyProps={{
                                              className: "!text-[13px]"
                                            }}
                                          />
                                        </ListItemButton>
                                      </Link>
                                    )}
                                  </Fragment>
                                ))}
                              </List>
                            </Collapse>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  ))
                }
              </List>
            </div>
          ))}
        </div>
        <div className="p-2 bg-white/50">
          <Button
            onClick={chatToggle}
            variant="contained"
            color={chatOpen ? "error" : (debounceAnimation ? "success" : "primary")}
            className={`flex items-center !justify-between w-full ${!chatOpen && totalUnreadMessages && debounceAnimation ? "animate-bounce" : ""}`}
          >
            <span>
              <span>{chatOpen ? t("close") : t("chat")}</span>
              {!chatOpen && totalUnreadMessages > 0 && (
                <span className="px-1 ml-1 text-xs font-bold text-black bg-gray-100 rounded">{totalUnreadMessages}</span>
              )}
            </span>
            <AvatarGroup
              slotProps={{
                additionalAvatar: {
                  sx: { width: 25, height: 25, fontSize: 13 }
                }
              }}
              max={5}
            >
              {onlineUsers.filter(x => x.id != CURRENT_USER.id).map((user, index) => (
                <BadgeOnline
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                  active={user.online ? "true" : undefined}
                  key={index}
                >
                  <Avatar
                    sx={{ width: 25, height: 25, fontSize: 13, bgcolor: randomHex() }}
                    {...getProfileImage(user)}
                  ></Avatar>
                </BadgeOnline>
              ))}
            </AvatarGroup>
          </Button>
        </div>
      </div>
    </Slide>
  );
};

export default Sidebar;